.detailsForm {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.fieldGroup {
  width: calc((100% / 2) - 10px);
}

.detailsTextArea {
  width: 100%;
}

.btnGetInTouch {
  border: 1px solid var(--marketplaceColor);
  color: white;
  position: relative;
}

.btnGetInTouch:hover {
  color: var(--marketplaceColor);
  background-color: var(--colorWhite);
}

.spinner {
  width: 24px;
  height: 24px;
  stroke: var(--colorSuccess);
  stroke-width: 3px;
  color: white;
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
}
