.nameFields {
  display: flex;
  justify-content: space-between;
}

.nameFields .input {
  width: 48%;
}


.input {
  margin-bottom: 12px;
}

.submitButton {
  padding: 0 24px;
  margin-top: 24px;
  position: relative;

  @media (--viewportMedium) {
    padding: 0;
    margin-top: 72px;
  }
}

/* .spinner {
  width: 24px;
  height: 24px;
  stroke: var(--colorSuccess);
  stroke-width: 3px;
  color: white;
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
} */
